<template>
  <div>
    <div class="card bg-white">
      <div class="flex justify-content-end mb-2">
        <Button
          :disabled="
            old_status === 'done' || !isSimpananPokok || !isSimpananWajib
          "
          :loading="isLoadingSave"
          icon="pi pi-save"
          class="mr-2"
          :label="id > 0 ? 'Update' : 'Simpan'"
          @click="onConfirmSave(!v$.$invalid)"
        />
        <Button
          :disabled="
            old_status === 'done' || !isSimpananPokok || !isSimpananWajib
          "
          icon="pi pi-plus"
          class="p-button-outlined mr-2"
          label="Item"
          @click="onAddData"
        />
        <Button
          v-show="form.details.length > 0 && old_status === 'draft'"
          type="button"
          icon="pi pi-refresh"
          label="Reset"
          class="p-button-outlined"
          @click="dialogReset = true"
        />
        <Button
          class="p-button-link"
          label="Kembali"
          @click="$router.push({ name: 'Simpanan' })"
        />
      </div>
      <div class="grid">
        <div class="col-8 md:col-6">
          <div class="formgrid grid">
            <div class="field col-12 md:col-5">
              <label>No Simpanan</label>
              <InputText
                v-model="form.si_no"
                class="w-full"
                input-class="w-full"
                maxlength="50"
                disabled
              />
            </div>
            <div class="field col-12 md:col-3">
              <label>Tgl Simpanan</label>
              <Calendar
                :disabled="old_status === 'done'"
                v-model="form.si_at"
                dateFormat="dd/mm/yy"
                class="w-full"
                input-class="w-full"
              />
            </div>
            <div class="field col-12 md:col-8">
              <label>Anggota</label>
              <AutoComplete
                ref="item"
                v-model="form.anggota"
                class="w-full"
                input-class="w-full"
                :min-length="2"
                :suggestions="acAnggota"
                :field="anggotaLabel"
                autofocus
                :class="{
                  'p-invalid': v$.form.anggota.$invalid && submitted,
                }"
                :disabled="
                  old_status === 'done' || this.form.details.length > 0
                "
                placeholder="Search Anggota"
                @complete="searchAnggota($event)"
                @item-select="selectAnggota"
                @focus="selectAllText('item')"
              >
                <template #item="slotProps">
                  <div v-if="slotProps.item">
                    <div>
                      <strong>
                        {{ slotProps.item.kode }} -
                        {{ slotProps.item.nama }}
                      </strong>
                    </div>
                    <div>Alamat: {{ slotProps.item.alamat }}</div>
                  </div>
                  <span v-else>
                    {{ slotProps.placeholder }}
                  </span>
                </template>
              </AutoComplete>
              <small
                v-if="
                  (v$.form.anggota.$invalid && submitted) ||
                  v$.form.anggota.$pending.$response
                "
                class="p-error"
                >{{ v$.form.anggota.required.$message }}</small
              >
            </div>
            <div class="field col-12 md:col-4">
              <label>Status</label>
              <Dropdown
                v-model="form.status"
                :options="list_status"
                optionValue="value"
                optionLabel="label"
                optionDisabled="disabled"
                class="w-full"
                input-class="w-full"
                :disabled="old_status === 'done'"
              />
            </div>
          </div>
        </div>
        <div class="col-4 md:col-6" v-if="!id">
          <Message severity="warn" :closable="false" v-if="!isSimpananPokok">
            <template #default>
              Anggota belum membayar simpanan pokok
              <Button
                label="Item"
                icon="pi pi-plus"
                class="ml-2 p-button-sm"
                @click="onAddDataPokok"
              />
            </template>
          </Message>

          <Message severity="warn" :closable="false" v-if="!isSimpananWajib">
            <template #default>
              Anggota belum membayar simpanan wajib
              <Button
                label="Item"
                icon="pi pi-plus"
                class="ml-2 p-button-sm"
                @click="onAddDataWajib"
              />
            </template>
          </Message>
        </div>
      </div>
      <div class="card">
        <fx-table
          :items="form.details"
          :total="form.details.length"
          :loading="isLoading"
          :paginator="false"
          hasFooter
          showGridlines
        >
          <template #columns>
            <Column
              field="nama_simpanan"
              header="nama simpanan"
              style="min-width: 8rem; background-color: #f8f9fa"
            />
            <Column
              field="keterangan"
              header="keterangan"
              style="min-width: 15rem; background-color: #f8f9fa"
            />
            <Column
              field="jumlah"
              header="jumlah"
              style="min-width: 10rem; justify-content: flex-end"
            >
              <template #body="slotProps">
                <span style="text-align: right">
                  {{ formatCurrency(slotProps.data.jumlah) }}
                </span>
              </template>
            </Column>
            <Column style="flex-grow: 1; justify-content: flex-end">
              <template #body="{ data }">
                <Button
                  :disabled="
                    old_status === 'done' ||
                    data.jsim_id == 1 ||
                    data.jsim_id == 2
                  "
                  type="button"
                  icon="pi pi-pencil"
                  class="
                    p-button-outlined p-button-secondary p-button-text
                    mr-2
                  "
                  @click="onEditData(data)"
                />
                <Button
                  :disabled="
                    old_status === 'done' ||
                    data.jsim_id == 1 ||
                    data.selisih > 1
                  "
                  type="button"
                  icon="pi pi-trash"
                  class="p-button-outlined p-button-danger p-button-text"
                  @click="onConfirmDeletion(data)"
                />
              </template>
            </Column>
          </template>
          <template #footer>
            <div class="p-d-flex p-jc-end p-ai-center" style="width: 100%">
              <table style="width: 100%">
                <tr>
                  <td colspan="3" style="text-align: right">
                    {{ formatCurrency(totalSimpanan.total || 0) }}
                  </td>
                  <td style="text-align: right"></td>
                </tr>
              </table>
            </div>
          </template>
        </fx-table>
      </div>
    </div>
    <!-- </form> -->
    <Dialog
      :header="editMode ? 'Edit Item Simpanan' : 'Tambah Item Simpanan'"
      v-model:visible="dialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '40vw' }"
      :modal="true"
    >
      <form-simpanan-item
        :item="formDetail"
        :loading="isLoadingSave"
        :listItemSimpanan="listItemSimpanan"
        :listWajib="listWajib"
        :globalSetting="globalSetting"
        @save="onSaveData"
        @close="dialog = false"
      />
    </Dialog>
    <Dialog
      header="Hapus Simpanan"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <span
          >Item <strong>{{ formDetail.nama_simpanan }}</strong> akan dihapus.
          Proses ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Hapus"
          icon="pi pi-trash"
          @click="onDeleteItemSimpanan"
          class="p-button-text p-button-danger"
        />
      </template>
    </Dialog>
    <Dialog
      header="Reset Form"
      v-model:visible="dialogReset"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '40vw' }"
      :modal="true"
      :closable="false"
    >
      <div class="confirmation-content">
        <span>Data form akan direset, Ulangi Input ?</span>
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          class="p-button-text p-button-secondary"
          @click="dialogReset = false"
        />
        <Button label="Yes" @click="onResetForm" />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D', 'S']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import useVuelidate from '@vuelidate/core'
import errorHandler from '@/helpers/error-handler'
import FxTable from '@/components/FxTable'
import { helpers, required } from '@vuelidate/validators'
import { formatCurrency } from '@/helpers/index'
import AnggotaService from '@/services/AnggotaService'
import SimpananService from '@/services/SimpananService'
import JsimService from '@/services/JsimService'
import SettingService from '@/services/SettingService'
import FormSimpananItem from '@/components/simpanan/FormSimpananItem'
import Hotkey from '@/components/Hotkey'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  components: {
    FormSimpananItem,
    FxTable,
    Hotkey,
  },
  data() {
    return {
      submitted: false,
      anggotaService: null,
      simpananService: null,
      jsimService: null,
      settingService: null,
      acAnggota: null,
      dialog: false,
      dialogHapus: false,
      dialogReset: false,
      isLoading: false,
      isLoadingSave: false,
      items: [],
      listJsim: [],
      listItemSimpanan: [],
      listWajib: [],
      globalSetting: [],
      editMode: false,
      form: {
        si_no: '',
        si_at: new Date(),
        anggota_id: 0,
        status: 'draft',
        details: [],
      },
      formDetail: {
        jsim_id: '',
        jumlah: 1,
      },
      list_status: [
        { value: 'draft', label: 'draft' },
        { value: 'done', label: 'done' },
      ],
      old_status: 'draft',
      listDeleted: [],
      isSimpananPokok: true,
      isSimpananWajib: true,
      isAvailableWajib: true,
    }
  },
  created() {
    this.anggotaService = new AnggotaService()
    this.simpananService = new SimpananService()
    this.jsimService = new JsimService()
    this.settingService = new SettingService()
  },

  mounted() {
    this.$nextTick(() => {
      if (this.$refs.item) {
        const inputElement = this.$refs.item.$el.querySelector('input')
        if (inputElement) {
          inputElement.focus()
        }
      }
    })

    if (this.id > 0) {
      this.isLoading = true
      this.simpananService
        .get(`/${this.id}`)
        .then((res) => {
          if (res.data.status === 200) {
            this.form = res.data.data
            this.old_status = this.form.status
            this.form.si_at = this.form.si_at ? new Date(this.form.si_at) : null
          }
        })
        .catch((err) => {
          errorHandler(err, 'Simpanan', this)
        })
        .finally(() => (this.isLoading = false))
    }

    this.jsimService
      .get()
      .then((res) => {
        this.listJsim = res.data.data
      })
      .catch((err) => {
        errorHandler(err, 'Data Items', this)
      })

    this.settingService
      .get()
      .then((res) => {
        this.globalSetting = res.data
      })
      .catch((err) => {
        errorHandler(err, 'Data Setting', this)
      })
  },
  computed: {
    totalSimpanan() {
      // jumlah
      let jumlah = this.form.details.reduce((total, detail) => {
        total += detail.jumlah
        return total
      }, 0)

      const total = {
        total: jumlah,
      }
      return total
    },
  },
  watch: {
    'form.anggota_id'(value) {
      if (value > 0 && !this.form.details.length > 0) {
        this.simpananService
          .get(
            `/anggota?filter[anggota_id]=${this.form.anggota_id}&filter[jsim_id]=1&filter[status]=done`
          )
          .then((res) => {
            res.data.data
              ? (this.isSimpananPokok = true)
              : (this.isSimpananPokok = false)
          })
        this.simpananService
          .get(
            `/anggota?filter[anggota_id]=${this.form.anggota_id}&filter[jsim_id]=2&filter[status]=done`
          )
          .then((res) => {
            res.data.data
              ? (this.isSimpananWajib = true)
              : (this.isSimpananWajib = false)
          })
        this.simpananService
          .get(`/wajib?anggota_id=${this.form.anggota_id}`)
          .then((res) => {
            this.listWajib = res.data.data
            this.listItemSimpanan =
              this.listWajib.selisih > 1
                ? this.listJsim.filter(
                    (item) => item.id !== 1 && item.id !== 2
                  )
                : this.listJsim.filter((item) => item.id !== 1)
          })
      }
    },
  },
  methods: {
    formatCurrency(val) {
      return formatCurrency(val, 0, 0)
    },
    searchAnggota(e) {
      this.anggotaService
        .get(`?filter[nama]=${e.query}&filter[status]=active`)
        .then((res) => {
          this.acAnggota = res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Data Anggota', this)
        })
    },
    selectAnggota(e) {
      this.form.anggota_id = e.value.id
      this.anggotaLabel(e)
    },
    anggotaLabel(e) {
      return e.kode + ' - ' + e.nama
    },
    selectAllText(event) {
      if (event) {
        this.$refs[event].$el.querySelector('input').select()
      }
    },
    onAddDataPokok() {
      const simpananPokok = this.globalSetting.find(
        (setting) => setting.id === 1
      )
      let data = {
        jsim_id: 1,
        jumlah: simpananPokok.value,
        keterangan: 'Simpanan Pokok ' + this.form.anggota.nama,
      }
      this.onSaveData(data)
      this.isSimpananPokok = true
    },
    onAddDataWajib() {
      const simpananWajib = this.globalSetting.find(
        (setting) => setting.id === 2
      )
      this.editMode = false
      this.formDetail = {
        jsim_id: 2,
        jumlah: simpananWajib.value,
        total: simpananWajib.value * this.listWajib.selisih,
        jumlahBulan: 0,
        jumlahTambahan: 0,
        selisih: this.listWajib.selisih,
        dariBulan: this.listWajib.dari_bulan,
        sampaiBulan: this.listWajib.sampai_bulan,
      }
      this.dialog = true
    },
    onAddData() {
      if (!this.form.anggota_id) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Simpanan',
          detail: 'Anggota harus diisi terlebih dahulu.',
          life: 3000,
        })
        return
      }

      this.editMode = false
      this.formDetail = {
        jsim_id: '',
        jumlah: 0,
      }
      this.dialog = true
    },
    async onEditData(data) {
      this.editMode = true
      this.formDetail = Object.assign({}, data)
      this.dialog = true
    },
    totalDetail(detail) {
      const total = {
        total: detail.jumlah,
      }
      return total
    },
    async onSaveData(data) {
      console.log('cek', data)
      const detailItem = Object.assign({}, data)

      const idx = this.form.details.findIndex(
        (item) => item.jsim_id === detailItem.jsim_id
      )

      const getJsim =
        this.listJsim.filter((item) => item.id === detailItem.jsim_id).length >
        0
          ? this.listJsim.filter((item) => item.id === detailItem.jsim_id)[0]
          : {}

      if (this.editMode) {
        Object.assign(this.form.details[idx], { ...detailItem })
        this.dialog = this.editMode ? false : this.dialog
      } else {
        let payloads = []
        let message = ''

        if (detailItem.jsim_id == 2) {
          if (detailItem.selisih > 1) {
            const startDate = new Date(detailItem.dariBulan + ' 1')
            const endDate = new Date(detailItem.sampaiBulan + ' 1')
            let currentDate = new Date(startDate)

            while (currentDate <= endDate) {
              const monthYear = currentDate.toLocaleString('id-ID', {
                month: 'long',
                year: 'numeric',
              })

              const payload = {
                nama_simpanan: getJsim.nama,
                keterangan: 'Simpanan Wajib ' + monthYear,
                ...detailItem,
              }

              payloads.push(payload)
              currentDate.setMonth(currentDate.getMonth() + 1)
            }

            message = `${payloads.length} items berhasil ditambahkan.`
          } else if (detailItem.jumlahBulan > 0) {
            // Find the last month/year from form.details with jsim_id = 2
            const lastJsimDetail = this.form.details
              .filter((item) => item.jsim_id === 2)
              .sort(
                (a, b) =>
                  new Date(
                    b.keterangan.split(' ')[2],
                    new Date(b.keterangan.split(' ')[1] + ' 1').getMonth()
                  ) -
                  new Date(
                    a.keterangan.split(' ')[2],
                    new Date(a.keterangan.split(' ')[1] + ' 1').getMonth()
                  )
              )[0]

            console.log('cek last', lastJsimDetail)

            // Extracting month and year correctly from the lastJsimDetail
            let startDate = lastJsimDetail
              ? new Date(
                  lastJsimDetail.keterangan.split(' ')[2], // Year
                  new Date(
                    lastJsimDetail.keterangan.split(' ')[1] + ' 1'
                  ).getMonth(), // Month (correctly parsed)
                  1
                )
              : new Date(detailItem.dariBulan + ' 1') // Fallback to detailItem.dariBulan

            console.log('cek startDate', startDate)

            // Adjust startDate by one month since it's supposed to start after the last entry
            startDate.setMonth(startDate.getMonth() + 1)

            for (let i = 0; i < detailItem.jumlahBulan; i++) {
              const currentMonth = new Date(startDate)
              currentMonth.setMonth(startDate.getMonth() + i)
              const monthYear = currentMonth.toLocaleString('id-ID', {
                month: 'long',
                year: 'numeric',
              })

              const payload = {
                nama_simpanan: getJsim.nama,
                keterangan: 'Simpanan Wajib ' + monthYear,
                ...detailItem,
              }

              payloads.push(payload)
            }

            message = `${payloads.length} items berhasil ditambahkan.`
          } else {
            const payload = {
              nama_simpanan: getJsim.nama,
              keterangan: 'Simpanan Wajib ' + detailItem.dariBulan,
              ...detailItem,
            }

            payloads.push(payload)
            message = 'Item berhasil ditambahkan.'
          }
          this.isSimpananWajib = true
        } else {
          const payload = {
            nama_simpanan: getJsim.nama,
            keterangan: getJsim.nama + ' ' + this.form.anggota.nama,
            ...detailItem,
          }

          payloads.push(payload)
          message = 'Item berhasil ditambahkan.'
        }

        if (detailItem.jsim_id == 2) {
          this.form.details.push(...payloads)
          this.$toast.add({
            severity: 'success',
            summary: 'Simpanan',
            detail: message,
            life: 3000,
          })
          this.dialog = false
        } else {
          if (idx === -1) {
            this.form.details.push(...payloads)
            this.$toast.add({
              severity: 'success',
              summary: 'Simpanan',
              detail: message,
              life: 3000,
            })
            this.dialog = false
          } else {
            this.$toast.add({
              severity: 'error',
              summary: 'Simpanan',
              detail: 'Item ' + payloads[0].nama_simpanan + ' sudah ada',
              life: 3000,
            })
          }
        }
      }
    },
    onConfirmDeletion(data) {
      this.formDetail = data
      this.dialogHapus = true
    },
    onDeleteItemSimpanan() {
      const index = this.form.details.findIndex(
        (el) => el.jsim_id === this.formDetail.jsim_id
      )
      if (index !== -1) {
        if (this.id && this.form.details[index].id) {
          this.listDeleted.push(this.form.details[index].id)
        }
        this.form.details.splice(index, 1)
        this.$toast.add({
          severity: 'success',
          summary: 'Data Item Simpanan',
          detail: 'Data berhasil dihapus.',
          life: 3000,
        })
      }
      this.dialogHapus = false
    },
    onConfirmSave(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }

      if (this.form.details.length === 0) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Simpanan',
          detail: 'Item simpanan minimal 1 data.',
          life: 3000,
        })
        return
      }

      const deleted = this.id ? { deleted: this.listDeleted } : {}

      const si_at = this.form.si_at
        ? { si_at: dayjs(this.form.si_at).format('YYYY-MM-DD') }
        : null
      const form = {
        ...si_at,
        status: this.form.status,
        si_no: this.form.si_no,
        anggota_id: this.form.anggota_id,
        details: this.form.details.map((item) => {
          const id = item.id ? { id: item.id } : this.id ? { id: 0 } : {}
          return {
            ...id,
            jsim_id: item.jsim_id,
            keterangan: item.keterangan,
            jumlah: item.jumlah,
          }
        }),
        ...deleted,
      }

      if (!this.id) {
        this.isLoadingSave = true
        this.simpananService
          .add(form)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Simpanan',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.$router.push({ name: 'Simpanan' })
            }
          })
          .catch((err) => {
            errorHandler(err, 'Simpanan', this)
          })
          .finally(() => {
            this.deleted = []
            this.isLoadingSave = false
          })
      } else {
        this.isLoadingSave = true
        this.simpananService
          .update(this.id, form)
          .then((res) => {
            console.log('cek', res.data.status)
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Simpanan',
                detail: 'Data berhasil diupdate.',
                life: 3000,
              })
              this.$router.push({ name: 'Simpanan' })
            }
          })
          .catch((err) => {
            errorHandler(err, 'Simpanan', this)
          })
          .finally(() => {
            this.isLoadingSave = false
          })
      }
    },
    onResetForm() {
      this.form = {
        si_no: '',
        si_at: new Date(),
        anggota_id: 0,
        status: 'draft',
        details: [],
      }
      this.dialogReset = false
    },
    onTriggerHotkey(payload) {
      switch (payload.keyString) {
        case 'D':
          this.add()
          break
        case 'S':
          this.save(!this.v$.$invalid)
          break
      }
    },
  },
  validations() {
    return {
      form: {
        si_at: {
          required: helpers.withMessage('Tgl Simpanan harus diisi.', required),
        },
        anggota: {
          required: helpers.withMessage('Anggota harus diisi.', required),
        },
      },
    }
  },
}
</script>

<style scoped>
.p-message.p-message-warn {
  background: #ffb300;
  border: solid #cf9202;
  border-width: 0 0 0 4px;
  color: var(--text-color);
  padding: 0rem; /* Adjust padding to make the message box smaller */
  font-size: 0.5rem; /* Adjust font size to make the text smaller */
}

.p-message .p-message-text {
  font-size: 0.5rem; /* Adjust the font size of the text inside the message */
}
</style>
