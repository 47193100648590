<template>
  <div>
    <!-- Untuk mencegah tekan enter langsung submit -->
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="grid">
        <div class="col-12">
          <div class="formgrid grid">
            <div class="field col-12 md:col-12">
              <label>Jenis Simpanan </label>
              <Dropdown
                v-model="form.jsim_id"
                :options="listItemSimpanan"
                :filter="true"
                optionValue="id"
                optionLabel="nama"
                placeholder="Pilih Jenis Simpanan"
                class="w-full"
                input-class="w-full"
                :showClear="true"
                :class="{ 'p-invalid': v$.form.jsim_id.$invalid && submitted }"
                :disabled="form.jsim_id === 1 || form.jsim_id === 2"
                @change="onChangeSimpanan"
              >
                <small
                  v-if="
                    (v$.form.jsim_id.$invalid && submitted) ||
                    v$.form.jsim_id.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.jsim_id.required.$message }}</small
                >
              </Dropdown>
            </div>
            <div
              v-if="form.jsim_id === 2 && form.selisih == 1"
              class="field col-12 md:col-12"
            >
              <label>Simpanan Wajib Bulan </label>
              <InputText
                v-model="form.dariBulan"
                class="w-full"
                input-class="w-full"
                disabled
              />
            </div>
            <div
              v-if="form.jsim_id === 2 && form.selisih > 1"
              class="field col-6 md:col-6"
            >
              <label>Dari Bulan </label>
              <InputText
                v-model="form.dariBulan"
                class="w-full"
                input-class="w-full"
                disabled
              />
            </div>
            <div
              v-if="form.jsim_id === 2 && form.selisih > 1"
              class="field col-6 md:col-6"
            >
              <label>Sampai Bulan </label>
              <InputText
                v-model="form.sampaiBulan"
                class="w-full"
                input-class="w-full"
                disabled
              />
            </div>
            <div
              v-if="form.jsim_id === 2 && form.selisih < 2"
              class="field col-12 md:col-12"
            >
              <input
                :checked="bayarSekaligus"
                type="checkbox"
                id="bayarSekaligus"
                @click="onCheckClick"
              />
              <label for="active">Bayar Beberapa Bulan Kedepan</label>
            </div>
            <div class="field col-6 md:col-6" v-if="bayarSekaligus">
              <label>Jumlah Bulan </label>
              <div class="p-inputgroup flex-1">
                <InputNumber
                  ref="jumlah"
                  v-model="form.jumlahBulan"
                  class="w-full"
                  input-class="w-full"
                  @input="onAddJumlahBulan"
                  @focus="selectAllText('jumlah')"
                />
                <span class="p-inputgroup-addon"> Bulan </span>
              </div>
            </div>
            <div class="field col-6 md:col-6" v-if="bayarSekaligus">
              <label>Tambahan </label>
              <div class="p-inputgroup flex-1">
                <span class="p-inputgroup-addon"> Rp </span>
                <InputNumber
                  v-model="form.jumlahTambahan"
                  class="w-full"
                  input-class="w-full"
                  locale="id-ID"
                  :min-fraction-digits="0"
                  :max-fraction-digits="0"
                  disabled
                />
              </div>
            </div>
            <div class="field col-12 md:col-12">
              <label>Jumlah</label>
              <div class="p-inputgroup flex-1">
                <span class="p-inputgroup-addon"> Rp </span>
                <InputNumber
                  ref="item"
                  v-model="form.jumlah"
                  class="w-full"
                  input-class="w-full"
                  locale="id-ID"
                  :min-fraction-digits="0"
                  :max-fraction-digits="0"
                  :allow-empty="false"
                  :class="{ 'p-invalid': v$.form.jumlah.$invalid && submitted }"
                  :disabled="form.jsim_id === 2"
                  @focus="selectAllText('item')"
                />
                <small
                  v-if="
                    (v$.form.jumlah.$invalid && submitted) ||
                    v$.form.jumlah.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.jumlah.required.$message }}</small
                >
              </div>
            </div>
            <div class="field col-12 md:col-12" v-if="form.jsim_id === 2">
              <label>Total</label>
              <div class="p-inputgroup flex-1">
                <span class="p-inputgroup-addon"> Rp </span>
                <InputNumber
                  v-model="form.total"
                  class="w-full"
                  input-class="w-full"
                  locale="id-ID"
                  :allow-empty="false"
                  :disabled="form.jsim_id === 2"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="$emit('close')"
          class="p-button-text mr-2"
        />
        <Button
          :loading="loading"
          :label="editMode ? 'Ubah' : 'Tambahkan'"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    listItemSimpanan: {
      type: Array,
      default() {
        return []
      },
    },
    listWajib: {
      type: Object,
      default() {
        return {}
      },
    },
    globalSetting: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      submitted: false,
      bayarSekaligus: false,
      periode: false,
      originalTotal: 0,
      originalJumlahBulan: 0,
      originalJumlahTambahan: 0,
    }
  },
  created() {},
  async mounted() {},
  computed: {
    form() {
      return this.item
    },
    jumlahWajib() {
      return this.item.jumlah
    },
  },
  methods: {
    selectAllText(event) {
      if (event) {
        this.$refs[event].$el.querySelector('input').select()
      }
    },
    handleSubmit(isFormValid) {
      this.submitted = true
      if (!isFormValid) {
        return
      }
      this.form.bayarSekaligus = this.bayarSekaligus
      this.$emit('save', this.form)
    },
    onChangeSimpanan() {
      const simpananWajib = this.globalSetting.find(
        (setting) => setting.id === 2
      )

      if (this.form.jsim_id == 2) {
        this.form.selisih = 0
        this.form.jumlah = simpananWajib.value
        this.form.total = simpananWajib.value
        this.form.dariBulan = this.listWajib.dari_bulan
      }
    },
    onAddJumlahBulan(e) {
      const simpananWajib = this.globalSetting.find(
        (setting) => setting.id === 2
      )

      this.form.jumlahTambahan = simpananWajib.value * e.value
      this.form.total = this.originalTotal + this.form.jumlahTambahan
    },
    onCheckClick() {
      if (this.bayarSekaligus) {
        // Unchecking, reset to original values
        this.form.jumlahBulan = this.originalJumlahBulan
        this.form.jumlahTambahan = this.originalJumlahTambahan
        this.form.total = this.originalTotal
      } else {
        // Checking, save original values
        this.originalTotal = this.form.total
        this.originalJumlahBulan = this.form.jumlahBulan
        this.originalJumlahTambahan = this.form.jumlahTambahan
      }
      this.bayarSekaligus = !this.bayarSekaligus
    },
  },
  validations() {
    return {
      form: {
        jsim_id: {
          required: helpers.withMessage('Nama harus diisi.', required),
        },
        jumlah: {
          required: helpers.withMessage('Min harus diisi.', required),
        },
      },
    }
  },
}
</script>
